import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { IconButton } from "@mui/material";
import styles from "../navbar.module.css";
import BackIcon from "../../../public/images/back_button.svg";

const BackButton = ({ onBackClick, noNavbar }) => {
  const router = useRouter();

  const onBackButtonClick = (e) => {
    if (!onBackClick) router.back();
    else onBackClick();
  };

  return (
    <IconButton
      className={
        noNavbar ? styles.backbutton__button__noNav : styles.backbutton__button
      }
      onClick={onBackButtonClick}
    >
      <Image src={BackIcon} priority />
    </IconButton>
  );
};

export default BackButton;
