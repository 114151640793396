import UITooltip from "./tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const ClickUITooltip = ({
  title,
  open,
  handleClose,
  direction = "top",
  children,
  ...props
}) => {
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <UITooltip
          PopperProps={{ disablePortal: true }}
          open={open}
          onClose={handleClose}
          title={title}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          placement={direction}
          {...props}
        >
          {children}
        </UITooltip>
      </div>
    </ClickAwayListener>
  );
};

export default ClickUITooltip;
