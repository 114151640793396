import React, { useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { Button, Typography, IconButton } from "@mui/material";
import config from "../../config";
import styles from "./share.module.css";
import { toast } from "react-toastify";
import ShareIcon from "../../public/images/icons/share_button.svg";
import ShareGreenIcon from "../../public/images/icons/share_button_green.svg";
import ClickUITooltip from "./tooltip.click";
import { captureEvent } from "../google-analytics/tag";
import { EVENTNAME, SECTION } from "../google-analytics/constants";
import { copyToClipboard } from "../../utils/browser.api";
import { createURL } from "../../utils/string";
import useShareClick from "../../utils/use.share.click";

/**
 * @typedef {Object} ShareButtonProps
 * @property {("trip" | "location" | "host" | "search")} entity
 * @property {string} shareLink
 * @property {number} entityId
 * @property {boolean} isMobile
 * @property {boolean} hideBorder
 * @property {boolean} noNavbar
 * @property {boolean} mobileGreenIcon
 * @property {boolean} mobileShowBorder
 * @property {string} className
 * @param {ShareButtonProps} props
 */
const ShareButton = ({
  entity,
  shareLink = "",
  entityId = 0,
  isMobile = false,
  hideBorder = false,
  noNavbar = false,
  mobileGreenIcon = false,
  mobileShowBorder = false,
  className = "",
  buttonText = "Share",
}) => {
  const [copiedTooltipOpen, setCopiedTooltipOpen, handleShareClick] =
    useShareClick({
      entity,
      shareLink,
      entityId,
      isMobile,
      hideBorder,
      noNavbar,
      mobileGreenIcon,
      mobileShowBorder,
    });

  return (
    <>
      {!isMobile ? (
        <ClickUITooltip
          title="Link copied!"
          open={copiedTooltipOpen}
          handleClose={() => setCopiedTooltipOpen(false)}
          direction="bottom"
        >
          <span>
            <Button
              className={[
                className,
                hideBorder ? styles.share_button_noBorder : styles.share_button,
              ].join(" ")}
              variant="outline"
              disableElevation
              onClick={handleShareClick}
            >
              <Typography
                variant="text-base"
                component="span"
                color="neutral-700.main"
              >
                {buttonText}
              </Typography>
              <Image
                src={ShareGreenIcon}
                className={styles.share_button__web}
                priority
              />
            </Button>
          </span>
        </ClickUITooltip>
      ) : (
        <IconButton
          className={[
            className,
            noNavbar
              ? styles.share_button_mweb__noNav
              : styles.share_button_mweb,
            mobileShowBorder && styles.share_button_mweb__border,
          ].join(" ")}
          onClick={handleShareClick}
        >
          <Image src={mobileGreenIcon ? ShareGreenIcon : ShareIcon} priority />
        </IconButton>
      )}
    </>
  );
};

export default ShareButton;
