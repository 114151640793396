import React from "react";
import { Dialog, Stack, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Image from "next/image";
import BoatingWomanImg from "../../public/images/boating_woman.svg";
import styles from "./auth.module.css";

const desktopDialogStyle = {
  padding: "16px",
  maxWidth: "392px",
};

/**
 * This component renders a dialog and helps render the common designs between register/login dialog for desktop.
 * - The title of the dialog must be passed as function that returns JSX.
 * - Form fields must be passed as children, use `<AuthFormFields />`
 * component as child to stay consistent with the design
 */
function AuthFormTopWeb({
  isModalOpen,
  onModalClose,
  onModalCrossClickFn,
  title,
  children,
  renderImage = true,
}) {
  const handleCloseModalClick = () => {
    onModalClose();
    onModalCrossClickFn();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={onModalClose}
      PaperProps={{ style: desktopDialogStyle }}
    >
      <Stack rowGap="32px">
        <Stack className={styles.login__page__box__topCtn}>
          {title && title()}

          <IconButton
            onClick={handleCloseModalClick}
            sx={{
              height: "fit-content",
              position: "relative",
              top: "-8px",
              right: "-8px",
            }}
          >
            <CloseIcon color="neutral-800.main" />
          </IconButton>
        </Stack>

        {renderImage && (
          <Box sx={{ padding: "0 16px 0 32px" }}>
            <Image src={BoatingWomanImg} />
          </Box>
        )}

        {children}
      </Stack>
    </Dialog>
  );
}

export default AuthFormTopWeb;
