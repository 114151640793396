import { useRouter } from "next/router";
import { Box, Typography } from "@mui/material";
import Image from "next/image";
import styles from "../navbar.module.css";

import cohykGreenIcon from "../../../public/images/cohyk.svg";
import cohykIcon from "../../../public/images/cohyk_dark.svg";

const NavbarLogo = ({ contrastNav, isMobile }) => {
  const router = useRouter();
  const handleLogoClick = () => router.push("/");

  return (
    <Box className={styles.navbarLogoCtn} onClick={handleLogoClick}>
      {/* Wrap next/link inside an elem to style it */}
      <Box className={styles.navbarLogoIcon}>
        <Image
          src={contrastNav ? cohykGreenIcon : cohykIcon}
          alt="Cohyk logo"
          priority
        />
      </Box>

      {isMobile ? (
        <Typography
          className={styles.navbar__title__small}
          variant="text-2xl-semibold"
          noWrap
          component="div"
          color={contrastNav ? "neutral-900.main" : "secondary.main"}
        >
          Cohyk
        </Typography>
      ) : (
        <Typography
          className={styles.navbar__title}
          variant="text-2xl-semibold"
          noWrap
          component="div"
          color={contrastNav ? "primary.main" : "secondary.main"}
          sx={{
            display: "flex",
            "& .MuiTypography-root": { marginTop: "4px" },
          }}
        >
          Cohyk
        </Typography>
      )}
    </Box>
  );
};

export default NavbarLogo;
