import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppBar, Toolbar, Container } from "@mui/material";
import styles from "./navbar.module.css";

import useIsMobile from "../../utils/device.type.hook";
import NavbarLogo from "./components/navbar.logo";
import NavbarLinks from "./components/navbar.links";
import BackButton from "./components/back.button";
import ShareButton from "../ui/share.button";
import { captureEvent } from "../google-analytics/tag";
import { EVENTNAME, SECTION } from "../google-analytics/constants";

/**
 * Navbar props:
 * 1. contrastNav -> if enabled, navbar elements have a green theme with contrasting colors; else all white
 * 2. opaqueNav [small screens only] -> if enabled, navbar background is opaque; else translucent
 * 3. showFeedbackPill [small screens only] -> shows feedback pill on navbar (deprecated)
 * 4. showBackButton -> shows back button just below navbar
 * 5. showShareButton -> shows share button just below navbar
 * 6. entity -> page type: {host|location|trip|search}
 * 7. entityId -> id of the above entity
 */
const Navbar = (props) => {
  const {
    contrastNav,
    opaqueNav,
    showBackButton,
    showShareButton,
    entity,
    entityId,
    transparentNav = false,
    classNameOnScroll = null,
    scrollHeight = null,
  } = props;

  const [scrollY, setScrollY] = useState(
    typeof window !== "undefined" ? window.scrollY : 0
  );
  const isMobile = useIsMobile();
  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);
  const userProfile = useSelector((state) => state.AuthReducer.profile);
  const isHostLoggedIn = isLoggedIn && userProfile?.isHost;

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <AppBar
      elevation={0}
      className={[
        contrastNav
          ? "bgcolor-navbar-light"
          : opaqueNav
          ? "bgcolor-navbar-dark"
          : "bgcolor-navbar-dark-clear",
        styles.navbar__appbar,
        transparentNav && styles.navbar__appbar__transparent,
        classNameOnScroll && scrollHeight && scrollY > scrollHeight
          ? classNameOnScroll
          : "",
      ].join(" ")}
      sx={{
        backgroundColor: {
          xs: "inherit",
          md: "transparent !important",
        },
        position: {
          xs: "fixed",
          md: "absolute",
        },
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{
            justifyContent: {
              md: "space-between",
            },
          }}
        >
          <NavbarLogo contrastNav={contrastNav} isMobile={isMobile} />
          <NavbarLinks
            isMobile={isMobile}
            contrastNav={contrastNav}
            isLoggedIn={isLoggedIn}
            isHostLoggedIn={isHostLoggedIn}
            userProfile={userProfile}
          />
        </Toolbar>
      </Container>
      {showBackButton && <BackButton />}
      {showShareButton && (
        <ShareButton entity={entity} entityId={entityId} isMobile />
      )}
    </AppBar>
  );
};
export default Navbar;
