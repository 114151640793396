import copy from 'copy-to-clipboard';
import { toast } from "react-toastify";

export const copyToClipboard = (text, successFn) => {
  let status = copy(text, { format: "text/plain" });

  if (status && typeof successFn == "function") successFn();
  else {
    toast("Copy action has been disabled. Open link in browser.", {
      className: "Toastify__toast__background-danger",
      bodyClassName: "toast__body",
      autoClose: 3000,
    });
  }
};
