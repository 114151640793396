import { useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  Container,
  Slide,
  Stack,
  CircularProgress,
} from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Image from "next/image";
import styles from "./auth.module.css";
import AuthOTP from "./otp.auth";
import useIsMobile from "../../utils/device.type.hook";
import cohykContrastIcon from "../../public/images/cohyk_contrast.svg";
import cohykClearImg from "../../public/images/logo_clear.svg";
import { handlePostApiCall } from "../../utils/api.call";
import AuthFormTopWeb from "./auth.form.top.web";
import AuthFormFields from "./auth.form.fields";
import { captureEvent } from "../google-analytics/tag";
import { EVENTNAME, SECTION } from "../google-analytics/constants";
import { checkIfValidInstaHandle } from "../../utils/string";

const mobileDialogStyle = {
  backgroundColor: "#26a77c",
  borderRadius: "0px",
  width: "100%",
  marginTop: "auto",
  overscrollBehavior: "contain",
};

function RegisterHostDialogTitle() {
  return (
    <Typography variant="text-xl-semibold">
      Unlimited access to <br />{" "}
      <Typography variant="text-xl-semibold" color="brand-600.main">
        trip creation
      </Typography>{" "}
      and <br />{" "}
      <Typography variant="text-xl-semibold" color="brand-600.main">
        analytics
      </Typography>{" "}
      only for you
    </Typography>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HostRegister = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const isRegisterModalOpen = useSelector(
    (state) => state.AuthReducer.isHostRegisterModalOpen
  );

  const initRegisterDetails = {
    email: "",
    instaHandle: "",
  };
  const [registerDetails, setRegisterDetails] = useState(initRegisterDetails);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [isPasswordVisible, showPassword] = useState(false);
  const [submitButtonDisabled, setButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isValidInstaHandle, setIsValidInstaHandle] = useState(true);

  const handleShowPasswordClick = () => showPassword(!isPasswordVisible);

  const handleEmailChange = (e) => {
    setRegisterDetails((prev) => {
      return {
        ...prev,
        email: e.target.value,
      };
    });
  };

  const handlePasswordChange = (e) => {
    setRegisterDetails((prev) => {
      return {
        ...prev,
        password: e.target.value,
      };
    });
  };

  const handleInstaChange = (e) => {
    const value = e.target.value;
    if (value) {
      const isValid = checkIfValidInstaHandle(value);
      setIsValidInstaHandle(isValid);
    }
    setRegisterDetails((prev) => {
      return {
        ...prev,
        instaHandle: value,
      };
    });
  };

  const closeOtpDialog = () => setOtpDialogOpen(false);
  const handleCrossOtpDialogClick = () =>
    captureEvent(EVENTNAME.SIGNUP_HOST_OTP_CANCEL_CLICK, SECTION.SIGNUP_FORM);

  const closeRegisterHostDialog = () => {
    dispatch({ type: "CLOSE_HOST_REGISTER_MODAL" });
    setErrorMessage("");
  };
  const closeRegisterHostWebDialog = () => {
    closeRegisterHostDialog();
    setOtpDialogOpen(false);
  };
  const handleCrossRegisterDialogClick = () =>
    captureEvent(EVENTNAME.SIGNUP_HOST_CANCEL_CLICK, SECTION.SIGNUP_FORM);

  const handleHostRegisterSubmit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    captureEvent(EVENTNAME.SIGNUP_HOST_CLICK, SECTION.SIGNUP_FORM);

    if (!registerDetails.email || !registerDetails.instaHandle) {
      setErrorMessage("Please enter all the details to register.");
      setButtonDisabled(false);
      return;
    }

    const params = {
      email: registerDetails.email,
      instagram_handle: registerDetails.instaHandle,
    };
    const handleSuccessFn = (res) => res.data;

    const registerResponse = await handlePostApiCall({
      url: "auth/register",
      params,
      handleSuccessFn,
    });
    setButtonDisabled(false);

    if (registerResponse.status == "success") {
      setOtpDialogOpen(true);
      captureEvent(EVENTNAME.SIGNUP_HOST_COMPLETE_CLICK, SECTION.SIGNUP_FORM);
    } else
      setErrorMessage(
        registerResponse?.message || registerResponse?.detail?.message
      );
  };

  return (
    <>
      {isMobile && (
        <>
          <Dialog
            onClose={closeRegisterHostDialog}
            open={isRegisterModalOpen}
            fullScreen
            TransitionComponent={Transition}
            PaperProps={{ style: mobileDialogStyle }}
          >
            <AppBar
              position="fixed"
              elevation={0}
              className={styles.auth__navbar}
            >
              <Container maxWidth="lg">
                <Toolbar disableGutters>
                  <Box className={styles.auth__navbar__box}>
                    <Box className={styles.auth__navbar__icon}>
                      <Image
                        src={cohykContrastIcon}
                        alt="Cohyk logo"
                        priority
                      />
                    </Box>
                    <Typography
                      className={styles.auth__navbar__title}
                      variant="text-2xl-semibold"
                      noWrap
                      component="div"
                      color="secondary"
                      sx={{ display: { md: "none", lg: "none" } }}
                    >
                      Cohyk
                    </Typography>
                    <IconButton
                      onClick={() => {
                        closeRegisterHostDialog();
                        handleCrossRegisterDialogClick();
                      }}
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 8,
                        paddingRight: "0px",
                      }}
                    >
                      <CloseIcon color="secondary" />
                    </IconButton>
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
            <Container className={styles.auth__page__ctn}>
              <Box className={styles.register__page__box}>
                <Typography
                  variant="text-2xl-semibold"
                  component="div"
                  color="secondary.main"
                  display="inline"
                >
                  Unlimited access to
                </Typography>
                <Typography
                  variant="text-2xl-semibold"
                  component="div"
                  color="#FFD028"
                  display="inline"
                >
                  {" "}
                  trip creation{" "}
                </Typography>
                <Typography
                  variant="text-2xl-semibold"
                  component="div"
                  color="secondary.main"
                  display="inline"
                >
                  and analytics only for you
                </Typography>
              </Box>
              <Box className={styles.register__img__box}>
                <Image
                  src={cohykClearImg}
                  alt="Cohyk logo on host register page"
                  priority
                />
              </Box>
            </Container>
            <Container className={styles.register__form__ctn}>
              <Box className={styles.register__form__box}>
                <Typography
                  variant="text-base-semibold"
                  color="neutral-900.main"
                  marginBottom="16px"
                >
                  Sign up as a host
                </Typography>

                <TextField
                  className={styles.auth__form__input}
                  placeholder="Email ID"
                  variant="outlined"
                  onChange={handleEmailChange}
                  sx={{ "& .MuiInputBase-root": { height: "56px" } }}
                  inputProps={{
                    autoCapitalize: "none",
                  }}
                />
                <TextField
                  className={styles.auth__form__input}
                  placeholder="Instagram handle"
                  variant="outlined"
                  onChange={handleInstaChange}
                  error={!isValidInstaHandle}
                  helperText={
                    isValidInstaHandle
                      ? ""
                      : "Please enter a valid instagram handle."
                  }
                  sx={{ "& .MuiInputBase-root": { height: "56px" } }}
                  inputProps={{
                    autoCapitalize: "none",
                  }}
                />
                {errorMessage && (
                  <Typography
                    className={styles.auth__error}
                    variant="text-sm"
                    color="rose-600.main"
                  >
                    {errorMessage}
                  </Typography>
                )}
                <Button
                  className={styles.auth__form__button}
                  disableElevation
                  variant="contained"
                  onClick={handleHostRegisterSubmit}
                  disabled={submitButtonDisabled}
                >
                  <Typography
                    variant="text-base-semibold"
                    component="div"
                    color="secondary"
                  >
                    {submitButtonDisabled ? (
                      <CircularProgress
                        color="secondary"
                        size={30}
                        thickness={5}
                      />
                    ) : (
                      "Sign up"
                    )}
                  </Typography>
                </Button>
              </Box>
            </Container>
          </Dialog>
          <AuthOTP
            isDialogOpen={otpDialogOpen}
            closeDialog={closeOtpDialog}
            crossDialogFn={handleCrossOtpDialogClick}
            closeAuthDialog={closeRegisterHostDialog}
            context="REGISTER"
            phoneNumberOrEmail={registerDetails.email}
          />
        </>
      )}
      {!isMobile && (
        <AuthFormTopWeb
          isModalOpen={isRegisterModalOpen}
          onModalClose={closeRegisterHostWebDialog}
          onModalCrossClickFn={
            otpDialogOpen
              ? handleCrossOtpDialogClick
              : handleCrossRegisterDialogClick
          }
          title={RegisterHostDialogTitle}
        >
          <Stack rowGap={!otpDialogOpen && "16px"}>
            {otpDialogOpen ? (
              <AuthOTP
                isDialogOpen={otpDialogOpen}
                closeDialog={closeOtpDialog}
                crossDialogFn={handleCrossOtpDialogClick}
                closeAuthDialog={closeRegisterHostWebDialog}
                context="REGISTER"
                phoneNumberOrEmail={registerDetails.email}
              />
            ) : (
              <AuthFormFields
                handleEmailChange={handleEmailChange}
                handlePasswordChange={handlePasswordChange}
                handleShowPasswordClick={handleShowPasswordClick}
                handleSubmitClick={handleHostRegisterSubmit}
                includePassword={false}
                isPasswordVisible={isPasswordVisible}
                includeInstagram
                isValidInstaHandle={isValidInstaHandle}
                handleInstagramHandleChange={handleInstaChange}
                formType="register"
                submitButtonText="Sign up as a host"
                submitDisabled={submitButtonDisabled}
                errorMessage={errorMessage}
              />
            )}
          </Stack>
        </AuthFormTopWeb>
      )}
    </>
  );
};

export default HostRegister;
