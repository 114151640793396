import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/system";
import BaseLayout from "./base.layout";
import Navbar from "../components/navbar/navbar";
import HostRegister from "../components/auth/host.register";
import Login from "../components/auth/login";

export default function NavbarLayout(props) {
  const {
    children,
    tripTypeMap,
    tripTypeArr,
    contrastNav,
    opaqueNav,
    showBackButton,
    showShareButton,
    entity,
    entityId,
    transparentNav,
    floatingNav = false,
    classNameOnScroll,
    scrollHeight,
  } = props;

  return (
    <BaseLayout>
      <Navbar
        contrastNav={contrastNav}
        opaqueNav={opaqueNav}
        showBackButton={showBackButton}
        showShareButton={showShareButton}
        entity={entity}
        entityId={entityId}
        transparentNav={transparentNav}
        classNameOnScroll={classNameOnScroll}
        scrollHeight={scrollHeight}
      />
      {!floatingNav && (
        <Box
          style={{ marginTop: "64px" }}
          display={{
            xs: "block",
            md: "none",
            lg: "none",
          }}
        />
      )}
      <Box>{children}</Box>
      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <HostRegister />
      <Login />
    </BaseLayout>
  );
}
