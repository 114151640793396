import React from "react";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styles from "./auth.module.css";

/**
 * - includeInstagram -> bool, to include Instagram Handle field
 * - formType -> enum("login", "register"), login will render "Forgot Password?" option
 * - submitButtonText -> ex, "Login", "Sign up as Host"
 */
function AuthFormFields({
  handleEmailChange,
  handlePasswordChange,
  handleShowPasswordClick,
  handleSubmitClick,
  includePassword = true,
  handleForgotPassword,
  isPasswordVisible,
  includeInstagram,
  isValidInstaHandle,
  handleInstagramHandleChange,
  formType,
  submitButtonText,
  submitDisabled,
  forgotButtonDisabled,
  errorMessage,
}) {
  return (
    <>
      <TextField
        className={styles.auth__form__input}
        placeholder="Email ID"
        variant="outlined"
        onChange={handleEmailChange}
        sx={{
          "& .MuiInputBase-root": {
            xs: { height: "56px" },
            md: { height: "44px" },
          },
        }}
        inputProps={{
          autoCapitalize: "none",
        }}
      />
      {includePassword && (
        <TextField
          className={styles.auth__form__input}
          placeholder="Password"
          variant="outlined"
          type={isPasswordVisible ? "text" : "password"}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowPasswordClick}
                >
                  {isPasswordVisible ? (
                    <VisibilityIcon color="#A2A2A2" />
                  ) : (
                    <VisibilityOffIcon color="#A2A2A2" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-root": {
              xs: { height: "56px" },
              md: { height: "44px" },
            },
          }}
        />
      )}
      {includeInstagram && (
        <TextField
          className={styles.auth__form__input}
          placeholder="Instagram Handle"
          variant="outlined"
          onChange={handleInstagramHandleChange}
          error={!isValidInstaHandle}
          helperText={
            isValidInstaHandle ? "" : "Please enter a valid instagram handle."
          }
          sx={{
            "& .MuiInputBase-root": {
              xs: { height: "56px" },
              md: { height: "44px" },
            },
          }}
          inputProps={{
            autoCapitalize: "none",
          }}
        />
      )}
      {errorMessage && (
        <Typography
          className={styles.auth__error}
          variant="text-sm"
          color="rose-600.main"
        >
          {errorMessage}
        </Typography>
      )}
      {formType === "login" && (
        <Box display="flex">
          <Typography
            className={styles.forgot_pass}
            variant="text-sm"
            color="primary.main"
            onClick={handleForgotPassword}
          >
            Forgot password?
          </Typography>
          <Box marginTop="-2px" marginLeft="4px">
            {forgotButtonDisabled && (
              <CircularProgress size={12} thickness={5} />
            )}
          </Box>
        </Box>
      )}
      <Button
        className={styles.auth__form__button}
        disableElevation
        variant="contained"
        onClick={handleSubmitClick}
        disabled={submitDisabled}
      >
        <Typography
          variant="text-base-semibold"
          component="div"
          color="secondary"
        >
          {submitDisabled ? (
            <CircularProgress color="secondary" size={30} thickness={5} />
          ) : (
            submitButtonText
          )}
        </Typography>
      </Button>
    </>
  );
}

export default AuthFormFields;
