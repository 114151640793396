import { useState } from "react";
import { useRouter } from "next/router";
import config from "../config";
import { createURL } from "./string";
import { toast } from "react-toastify";
import { copyToClipboard } from "./browser.api";
import { captureEvent } from "../components/google-analytics/tag";
import { EVENTNAME, SECTION } from "../components/google-analytics/constants";

/**
 * @typedef {Object} useShareClickProps
 * @property {("trip" | "location" | "host" | "search")} entity
 * @property {string} shareLink
 * @property {number} entityId
 * @property {boolean} isMobile
 * @param {useShareClickProps} props
 * @returns {[boolean, React.Dispatch<React.SetStateAction<boolean>>, (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void]}
 */
function useShareClick({
  entity,
  shareLink = "",
  entityId = 0,
  isMobile = false,
}) {
  const router = useRouter();
  const [copiedToolTipOpen, setCopiedToolTipOpen] = useState(false);

  if (!shareLink) shareLink = router.asPath;

  const getShareButtonEventData = (entity) => {
    switch (entity) {
      case "trip":
        return [EVENTNAME.SHARE_TRIP_CLICK, SECTION.TRIP_PAGE];
      case "location":
        return [EVENTNAME.SHARE_LOCATION_CLICK, SECTION.LOCATION_PAGE];
      case "host":
        return [EVENTNAME.SHARE_HOST_CLICK, SECTION.HOST_PAGE];
      case "search":
        return [EVENTNAME.SHARE_SEARCH_CLICK, SECTION.SEARCH_PAGE];
      default:
        return [];
    }
  };

  /**
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e
   */
  const handleShareClick = (e) => {
    e.preventDefault();

    const baseUrl = `${config.HOST}${shareLink}`;
    const url = createURL(baseUrl, "utm_medium=share");

    const successFn = () => {
      if (isMobile) {
        toast("Link copied!", {
          className: "Toastify__toast__background-success",
          bodyClassName: "toast__body",
          autoClose: 3000,
        });
      } else {
        setCopiedToolTipOpen(true);
      }
    };
    copyToClipboard(url, successFn);

    const [eventName, source] = getShareButtonEventData(entity);
    const eventDetails = {
      id: entityId,
      entity: entity,
    };
    captureEvent(eventName, source, eventDetails);
  };

  return [copiedToolTipOpen, setCopiedToolTipOpen, handleShareClick];
}

export default useShareClick;
