import { useRef, useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import Image from "next/image";
import styles from "../navbar.module.css";

import NavbarHamButton from "./navbar.ham";
import NavDrawer from "./navbar.drawer";
import feedbackIcon from "../../../public/images/icons/feedback.svg";
import feedbackDarkIcon from "../../../public/images/icons/feedback_dark.svg";
import Chip from "../../chip/chip";
import ALink from "../../ui/link";
import { logoutUser } from "../../auth/profile";
import { captureEvent } from "../../google-analytics/tag";
import { EVENTNAME, SECTION } from "../../google-analytics/constants";
import MsgBubble from "../../../public/images/icons/msg_bubble.svg";
import MsgGreenBubble from "../../../public/images/icons/msg_bubble_green.svg";
import { useIsNotifAvailable } from "../../chat/chat";
import { AUTH_TYPE } from "../../auth/login";
import ProfileGreenIcon from "../../../public/images/icons/profile_img_green.svg";
import BookmarkGreenIcon from "../../../public/images/icons/bookmark_green.svg";

const NavbarWebLinks = forwardRef(
  (
    {
      contrastNav,
      isLoggedIn,
      isHostLoggedIn,
      handleRegisterHostClick,
      handleLoginClick,
      userProfile,
      handleCreateAccountClick,
      handleProfileButtonClick,
    },
    profileButtonRef
  ) => {
    const dispatch = useDispatch();
    const router = useRouter();

    const handleLogoutClick = async () => {
      logoutUser().then((res) => {
        if (res.status == "success") {
          router.push("/");
          dispatch({ type: "RESET_USER_PROFILE" });
        }
      });
    };

    const handleFeedbackClick = () => {
      captureEvent(EVENTNAME.FEEDBACK_CLICK, SECTION.NAVBAR);
      dispatch({ type: "OPEN_FEEDBACK_MODAL" });
    };

    return (
      <>
        {!isLoggedIn && (
          <Box
            className={styles.navbar__navlinks__box}
            sx={{ display: "flex" }}
          >
            <Button disableRipple onClick={handleRegisterHostClick}>
              <Typography
                variant="text-base"
                color={contrastNav ? "neutral-900.main" : "secondary.main"}
                textTransform="initial"
              >
                Host a trip
              </Typography>
            </Button>
            <Box className={styles.navbar__navlinks__faqLinkCtn}>
              <ALink href="/faqs">
                <Typography
                  variant="text-base"
                  color={contrastNav ? "neutral-900.main" : "secondary.main"}
                  textTransform="initial"
                >
                  FAQs
                </Typography>
              </ALink>
            </Box>
            <Button disableRipple onClick={handleFeedbackClick}>
              <Typography
                variant="text-base"
                color={contrastNav ? "neutral-900.main" : "secondary.main"}
                textTransform="initial"
              >
                Feedback
              </Typography>
            </Button>
            <Button disableRipple onClick={handleLoginClick}>
              <Typography
                variant="text-base"
                color={contrastNav ? "neutral-900.main" : "secondary.main"}
                textTransform="initial"
              >
                Login
              </Typography>
            </Button>
            <Button
              className={
                contrastNav
                  ? styles.navbar__signin__btn__contrast
                  : styles.navbar__signin__btn
              }
              onClick={handleCreateAccountClick}
            >
              <Typography
                className={styles.navbar__signin__text}
                variant="text-sm"
                color={contrastNav ? "secondary.main" : "neutral-900.main"}
                textTransform="initial"
              >
                Create Account
              </Typography>
            </Button>
          </Box>
        )}
        {isLoggedIn && !isHostLoggedIn && (
          <Box
            className={styles.navbar__navlinks__box}
            sx={{ display: "flex" }}
          >
            <Button disableRipple onClick={() => router.push("/create-host")}>
              <Typography
                variant="text-base"
                color={contrastNav ? "neutral-900.main" : "secondary.main"}
                textTransform="initial"
              >
                Host a trip
              </Typography>
            </Button>
            <Button disableRipple onClick={handleFeedbackClick}>
              <Typography
                variant="text-base"
                color={contrastNav ? "neutral-900.main" : "secondary.main"}
                textTransform="initial"
              >
                Feedback
              </Typography>
            </Button>
            <Button disableRipple onClick={() => {}}>
              <Typography
                variant="text-base"
                color={contrastNav ? "neutral-900.main" : "secondary.main"}
                textTransform="initial"
                onClick={handleLogoutClick}
              >
                Logout
              </Typography>
            </Button>
            <Box
              className={styles.navbar__profileImgCtn}
              marginTop="3px"
              onClick={handleProfileButtonClick}
              ref={profileButtonRef}
            >
              <Image src={userProfile.hostPhotoURL} layout="fill" />
            </Box>
          </Box>
        )}
        {isHostLoggedIn && (
          <Box
            className={styles.navbar__navlinks__box}
            sx={{ display: "flex" }}
          >
            <Button disableRipple>
              <ALink href="/dashboard/analytics">
                <Typography
                  variant="text-base"
                  color={contrastNav ? "neutral-900.main" : "secondary.main"}
                  textTransform="initial"
                >
                  Analytics
                </Typography>
              </ALink>
            </Button>
            <Button disableRipple>
              <ALink href="/dashboard/manage-trips">
                <Typography
                  variant="text-base"
                  color={contrastNav ? "neutral-900.main" : "secondary.main"}
                  textTransform="initial"
                >
                  Manage Trips
                </Typography>
              </ALink>
            </Button>
            <Button disableRipple onClick={handleFeedbackClick}>
              <Typography
                variant="text-base"
                color={contrastNav ? "neutral-900.main" : "secondary.main"}
                textTransform="initial"
              >
                Feedback
              </Typography>
            </Button>
            <Button disableRipple onClick={() => {}}>
              <Typography
                variant="text-base"
                color={contrastNav ? "neutral-900.main" : "secondary.main"}
                textTransform="initial"
                onClick={handleLogoutClick}
              >
                Logout
              </Typography>
            </Button>
            <Box
              className={styles.navbar__profileImgCtn}
              marginTop="3px"
              onClick={handleProfileButtonClick}
              ref={profileButtonRef}
            >
              <Image src={userProfile.hostPhotoURL} layout="fill" />
            </Box>
          </Box>
        )}
      </>
    );
  }
);

function NavbarDropdownMenuItem({ label, href, icon, iconCtnClassName = "" }) {
  return (
    <Button className={styles.navbar_links__profileMenuPopover__item__btn}>
      <ALink
        href={href}
        aProps={{
          className: styles.navbar_links__profileMenuPopover__item,
        }}
      >
        <Box
          className={[
            styles.navbar_links__profileMenuPopover__item__iconCtn,
            iconCtnClassName,
          ].join(" ")}
        >
          <Image src={icon} layout="fill" />
        </Box>
        {label}
      </ALink>
    </Button>
  );
}

const NavbarLinks = ({
  isMobile,
  contrastNav,
  isLoggedIn,
  isHostLoggedIn,
  userProfile,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const isChatNotifAvailable = useIsNotifAvailable();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const profileButtonRef = useRef(null);

  const showChatBtn =
    isLoggedIn && isMobile && !router.pathname.includes("/messages");

  const handleOpenNavMenu = () => {
    setNavMenuOpen(true);
    captureEvent(EVENTNAME.NAV_MENU_OPEN_CLICK, SECTION.NAVBAR);
  };
  const setNavMenuClose = () => setNavMenuOpen(false);

  const handleRegisterHostClick = () => {
    dispatch({ type: "OPEN_HOST_REGISTER_MODAL" });
    captureEvent(EVENTNAME.ADD_TRIP_CLICK, SECTION.NAVBAR);
  };
  const handleCreateAccountClick = () => {
    dispatch({
      type: "OPEN_LOGIN_MODAL",
      payload: {
        authType: AUTH_TYPE.REGISTER,
      },
    });
    captureEvent(EVENTNAME.LOGIN_CLICK, SECTION.NAVBAR);
  };
  const handleLoginClick = () => {
    dispatch({ type: "OPEN_LOGIN_MODAL" });
    captureEvent(EVENTNAME.LOGIN_CLICK, SECTION.NAVBAR);
  };

  const handleFeedbackClick = () => {
    captureEvent(EVENTNAME.FEEDBACK_CLICK, SECTION.NAVBAR);
    dispatch({ type: "OPEN_FEEDBACK_MODAL" });
  };

  function handleChatClick() {
    if (router.pathname !== "/messages") router.push("/messages");
  }

  function handleProfileButtonClick() {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  }

  return (
    <>
      {isMobile ? (
        <>
          {!isLoggedIn && (
            <Box display="block" onClick={handleCreateAccountClick}>
              <Chip
                label="Create Account"
                color={contrastNav ? "neutral-200" : "neutral-500"}
                onClick={handleCreateAccountClick}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Box>
          )}
          {showChatBtn && (
            <IconButton
              className={styles.navbar__chatBtn}
              onClick={handleChatClick}
            >
              <Image src={contrastNav ? MsgGreenBubble : MsgBubble} />
              {isChatNotifAvailable && (
                <Box className={styles.navbar__chatBtn__notifDot} />
              )}
            </IconButton>
          )}

          {isLoggedIn ? (
            <Box
              position="relative"
              marginLeft="12px"
              borderRadius="50%"
              overflow="hidden"
              width="32px"
              height="32px"
              onClick={handleProfileButtonClick}
              ref={profileButtonRef}
            >
              <Image src={userProfile.hostPhotoURL} layout="fill" />
            </Box>
          ) : null}

          <NavbarHamButton
            handleOpenNavMenu={handleOpenNavMenu}
            contrastNav={contrastNav}
          />
          <NavDrawer
            navMenuOpen={navMenuOpen}
            setNavMenuClose={setNavMenuClose}
            isLoggedIn={isLoggedIn}
            isHostLoggedIn={isHostLoggedIn}
          />
        </>
      ) : (
        <NavbarWebLinks
          contrastNav={contrastNav}
          isLoggedIn={isLoggedIn}
          isHostLoggedIn={isHostLoggedIn}
          handleRegisterHostClick={handleRegisterHostClick}
          handleLoginClick={handleLoginClick}
          userProfile={userProfile}
          handleCreateAccountClick={handleCreateAccountClick}
          handleProfileButtonClick={handleProfileButtonClick}
          ref={profileButtonRef}
        />
      )}
      <Popover
        open={isProfileMenuOpen}
        onClose={handleProfileButtonClick}
        anchorEl={profileButtonRef.current}
        anchorOrigin={{
          vertical: isMobile ? 40 : 44,
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          className: styles.navbar_links__profileMenuPopover,
        }}
      >
        {isLoggedIn && !isHostLoggedIn && (
          <NavbarDropdownMenuItem
            label="Host a trip"
            href="/create-host"
            icon={ProfileGreenIcon}
            iconCtnClassName={
              styles.navbar_links__profileMenuPopover__item__iconCtn_small
            }
          />
        )}
        <NavbarDropdownMenuItem
          label="Saved Searches"
          href="/dashboard/saved-searches"
          icon={BookmarkGreenIcon}
        />
      </Popover>
    </>
  );
};

export default NavbarLinks;
